/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import axios from "axios";
import LCFQheader from "../../LCFQheader";
import Rypxradio from "../../../common/zzjg/Rypxradio";
import { RxUtil } from "@/assets/util.js";
import { format } from "@/assets/app.js";
export default {
  name: "lcfqPcsq",
  components: {
    LCFQheader,
    Rypxradio
  },
  data() {
    return {
      oneft: "/index",
      headTit: "新建申请",
      tit: "人员培训计划",
      showJhssrq: false,
      showJzrq: false,
      showPxxz: false,
      showPxlx: false,
      showGwpx: false,
      showListpage: false,
      minDate: new Date(),
      currentDate: new Date(),
      currentccTime: new Date(),
      //userSelect
      single: false,
      allowSelect: true,
      showUserDialog: false,
      showGroupDialog: false,
      nodeGroupVm: {},
      userID: "",
      solId: "",
      boDefId: "",
      actDefId: '',
      formKey: "",
      columnsPxxz: [],
      columnsPxlx: [],
      columnsGwpx: [],
      data: {
        jhbh: '',
        zzz: "",
        sjry: "",
        bm: "",
        zdr: ""
      },
      pxxzKey: "",
      pxlxKey: "",
      gwpxKey: ""
    };
  },
  created() {
    this.initBySolInstId();
    this.cgORxj();
  },
  methods: {
    initBySolInstId() {
      let instId = this.$route.params.instId;
      let newDate = new Date();
      this.data.ssjhrq = format(newDate);
      let solId = this.$route.params.solId;
      var url = _baseUrl + "/mobile/bpm/startForm.do?solId=" + solId;
      this.$ajax.post(url).then(res => {
        this.solId = res.data.bpmSolution.solId;
        this.actDefId = res.data.bpmSolution.actDefId;
        this.boDefId = res.data.formModels[0].boDefId;
        this.formKey = res.data.formModels[0].formKey;
        if (instId == 0) {
          //this.data.sqbh = res.data.formModels[0].jsonData.sqbh;
          let zzz = res.data.formModels[0].jsonData.zzz;
          let zzz_name = res.data.formModels[0].jsonData.zzz_name;
          let zzzObj = [{
            id: zzz,
            text: zzz_name
          }];
          this.data.zzz = JSON.stringify(zzzObj);
          let sjry = res.data.formModels[0].jsonData.sjry;
          let sjry_name = res.data.formModels[0].jsonData.sjry_name;
          let sjryObj = [{
            id: sjry,
            text: sjry_name
          }];
          this.data.sjry = JSON.stringify(sjryObj);
          let bm = res.data.formModels[0].jsonData.bm;
          let bm_name = res.data.formModels[0].jsonData.bm_name;
          let bmObj = [{
            id: bm,
            text: bm_name
          }];
          this.data.bm = JSON.stringify(bmObj);
          this.data.zdr = RxUtil.getCache("userName");
        }
      });
      let obj = {};
      let urlpxxz = _baseUrl + "/sys/core/sysDic/listByKey.do?key=PXXZ&_=1641802925290";
      this.$ajax.post(urlpxxz).then(res => {
        let list = res.data;
        let obj = {};
        list.map(item => {
          obj = {};
          obj = {
            text: item.name,
            value: item.key
          };
          this.columnsPxxz.push(obj);
        });
      });
      let urlpxlx = _baseUrl + "/sys/core/sysDic/listByKey.do?key=RYKHFS&_=1641867389110";
      this.$ajax.post(urlpxlx).then(res => {
        let list = res.data;
        let obj = {};
        list.map(item => {
          obj = {};
          obj = {
            text: item.name,
            value: item.key
          };
          this.columnsPxlx.push(obj);
        });
      });
      let urlgwpx = _baseUrl + "/sys/core/sysDic/listByKey.do?key=RYPX&_=1641867389111";
      this.$ajax.post(urlgwpx).then(res => {
        let list = res.data;
        let obj = {};
        list.map(item => {
          obj = {};
          obj = {
            text: item.name,
            value: item.key
          };
          this.columnsGwpx.push(obj);
        });
      });
    },
    async cgORxj() {
      let instId = this.$route.params.instId;
      if (instId != 0) {
        this.headTit = "我的草稿";
        this.oneft = "/myDrafts";

        /**测试接口**/
        var user_No = RxUtil.getCache("userNo"); //这里要重新储存
        console.log(user_No);
        var url2 = _baseUrl + "/mobile/bpm/getFormDataByInstId.do?instId=" + instId + "&userAccount=" + user_No;
        var this_ = this;
        this.$ajax.post(url2).then(response => {
          var resData = response.data.data.bos[0].data;
          console.log("草稿数据返填=", resData);
          this_.data.bz = resData.bz;
          this_.data.khfs = resData.khfs;
          this_.data.pxdd = resData.pxdd;
          this_.data.pxfy = resData.pxfy;
          this_.data.pxmd = resData.pxmd;
          this_.data.pxnr = resData.pxnr;
          this_.data.pxxm = resData.pxxm;
          this_.data.pxxs = resData.pxxs;
          this_.data.skry = resData.skry;
          this_.data.ssyq = resData.ssyq;
          this_.data.zzsw = resData.zzsw;
          this_.data.gwpx = resData.gwpx_name;
          this_.data.gwpx_name = resData.gwpx_name;
          this_.data.jhmc = resData.jhmc;
          this_.data.jzrq = resData.jzrq;
          this_.data.pxlx = resData.pxlx_name;
          this_.data.pxlx_name = resData.pxlx_name;
          this_.data.pxxz = resData.pxxz_name;
          this_.data.pxxz_name = resData.pxxz_name;
          this_.gwpxKey = resData.gwpx;
          this_.pxlxKey = resData.pxlx;
          this_.pxxzKey = resData.pxxz;
          this_.data.ssjhrq = resData.ssjhrq;
          this_.data.szjhnf = resData.szjhnf;
          this_.data.jhbh = resData.jhbh;
          let zzz = resData.zzz;
          let zzz_name = resData.zzz_name;
          let zzzObj = [{
            id: zzz,
            text: zzz_name
          }];
          this_.data.zzz = JSON.stringify(zzzObj);
          let sjry = resData.sjry;
          let sjry_name = resData.sjry_name;
          let sjryObj = [{
            id: sjry,
            text: sjry_name
          }];
          this_.data.sjry = JSON.stringify(sjryObj);
          let bm = resData.bm;
          let bm_name = resData.bm_name;
          let bmObj = [{
            id: bm,
            text: bm_name
          }];
          this_.data.bm = JSON.stringify(bmObj);
          this_.data.zdr = resData.zdr;
        });

        //     var url = _baseUrl + "/customizeProc/purRequisition/purRequisition/getJson.do?ids="+instId;
        // this.$ajax.post(url).then(res=>{
        // 	this.data = res.data;
        //       let urlUser1 = _baseUrl + `/mobile/oa/getUsersSearch.do?pageIndex=0&pageSize=20&searchVal=${dlrseach}`;
        //       this.$ajax.post(urlUser1).then(resdlr=>{
        //       let dlr = [{id:resdlr.data.rowList[0].userId,text:resdlr.data.rowList[0].fullname}];
        //         this.data.dlrName = JSON.stringify(dlr);
        //       })
        // });
      } else {
        //this.initBySolInstId();
      }
    },
    onConfiPxxz(value) {
      this.data.pxxz = value.text;
      this.pxxzKey = value.value;
      this.showPxxz = false;
    },
    confirmPxlx(value) {
      this.data.pxlx = value.text;
      this.pxlxKey = value.value;
      this.showPxlx = false;
    },
    confirmGwpx(value) {
      this.data.gwpx = value.text;
      this.gwpxKey = value.value;
      this.showGwpx = false;
    },
    onConfirmJhssrq(timestamp) {
      this.data.ssjhrq = format(timestamp);
      this.showJhssrq = false;
    },
    onConfirmJzrq(timestamp) {
      this.data.jzrq = format(timestamp);
      this.showJzrq = false;
    },
    //选代理人
    selectUserDialog(vm) {
      this.showUserDialog = true;
      this.$refs.sysUser.search();
      // 设置选择用户。
      this.$refs.sysUser.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeUserVm = vm;
    },
    selectUser(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        o.id = obj.userId;
        o.text = obj.fullname;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeUserVm.setValue(json);
      this.showUserDialog = false;
    },
    //科室
    selectGroupDialog(vm) {
      this.showGroupDialog = true;
      this.$refs.sysGroup.type = "group";
      this.$refs.sysGroup.search();
      // 设置选择用户。
      this.$refs.sysGroup.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeGroupVm = vm;
    },
    selectGroup(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        var test = {};
        o.id = obj.groupId;
        o.text = obj.name;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeGroupVm.setValue(json);
      this.showGroupDialog = false;
    },
    showList() {
      this.showListpage = true;
    },
    tdObj(item) {
      this.data.jhbh = item.F_JHBH;
      this.data.jhmc = item.F_JHMC;
      this.showListpage = false;
    },
    datas() {
      if (this.data.jhbh != "") {
        let zzz = JSON.parse(this.data.zzz);
        let sjry = JSON.parse(this.data.sjry);
        let bm = JSON.parse(this.data.bm);
        var jsonDataObj = {
          bos: [{
            boDefId: this.boDefId,
            formKey: this.formKey,
            data: {
              jhbh: this.data.jhbh,
              jhmc: this.data.jhmc,
              szjhnf: this.data.szjhnf,
              zzz: zzz[0].id,
              zzz_name: zzz[0].text,
              pxxm: this.data.pxxm,
              sjry: sjry[0].id,
              sjry_name: sjry[0].text,
              ssjhrq: this.data.ssjhrq,
              jzrq: this.data.jzrq,
              skry: this.data.skry,
              pxfy: this.data.pxfy,
              pxxs: this.data.pxxs,
              pxxz: this.pxxzKey,
              pxxz_name: this.data.pxxz,
              pxlx: this.pxlxKey,
              pxlx_name: this.data.pxlx,
              khfs: this.data.khfs,
              pxnr: this.data.pxnr,
              pxmd: this.data.pxmd,
              zzsw: this.data.zzsw,
              bm: bm[0].id,
              bm_name: bm[0].text,
              gwpx: this.gwpxKey,
              gwpx_name: this.data.gwpx,
              zdr: this.data.zdr,
              pxdd: this.data.pxdd,
              ssyq: this.data.ssyq,
              bz: this.data.bz
            }
          }]
        };
        var jsonData = JSON.stringify(jsonDataObj);
        var params = new FormData();
        params.append("solId", this.solId);
        params.append("bpmInstId", "");
        params.append("jsonData", jsonData);
        return params;
      } else {
        this.errorToast("信息未填完整！");
      }
    },
    //字段检查
    iN(str) {
      if (str == '' || str == null || typeof str == 'undefined') {
        return true;
      }
      return false;
    },
    //整体判断
    inspect() {
      if (this.iN(this.data.jhbh) || this.iN(this.data.jhmc) || this.iN(this.data.zzz) || this.iN(this.data.sjry) || this.iN(this.data.bm)) {
        return true;
      }
      return false;
    },
    onSubmit() {
      if (this.inspect()) {
        this.errorToast("启动失败！请检查信息是否填写完整", "1800");
        return;
      }
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/startProcess.do";
      this.$dialog.loading.open('正在启动流程,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        console.log(res);
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("启动成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("启动失败或信息未填完整！", "1800");
        }
      });
    },
    onPreservation() {
      if (this.inspect()) {
        this.errorToast("保存失败！请检查信息是否填写完整", "1800");
        return;
      }
      var params = this.datas();
      var url = _baseUrl + "/bpm/core/bpmInst/saveDraft.do";
      this.$dialog.loading.open('正在保存草稿,请稍候...');
      this.$ajax.post(url, params).then(res => {
        this.$dialog.loading.close();
        var data = res.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("保存成功！", 1000, 'index', {
            from: 'startForm'
          });
        } else {
          this.errorToast("保存失败或信息未填完整！", "1800");
        }
      });
    },
    onLct() {
      this.$router.push({
        name: "procImage",
        params: {
          id: this.actDefId,
          type: 'start'
        }
      });
    }
  }
};